import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Intro from "../components/page-intro"
import Helmet from "react-helmet"
import Img from "gatsby-image"
import { motion } from 'framer-motion'
import Swiper from "swiper"
import 'swiper/swiper-bundle.min.css'
import mrLogo from '../images/mark-ritchie/mark-ritchie-logo.svg'
import lflsLogo from '../images/mr-lfls-logo.svg'


const duration = 0.25

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { x: 0, y: 20, opacity: 0 },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
  },
}


const MarkRitchie = () => {
    const data = useStaticQuery(graphql`
      query {
      allDirectory {
        edges {
          node {
            id
          }
        }
      }
      mrNewspaperCovers: file(relativePath: {eq: "mr-newspaper-covers.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrNewspaper1: file(relativePath: {eq: "mr-newspaper-1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrNewspaper2: file(relativePath: {eq: "mr-newspaper-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrNewspaper3: file(relativePath: {eq: "mr-newspaper-3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrSF: file(relativePath: {eq: "mr-soul-focus.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrInsta1: file(relativePath: {eq: "mr-insta-1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrInsta2: file(relativePath: {eq: "mr-insta-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrInsta3: file(relativePath: {eq: "mr-insta-3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrInsta4: file(relativePath: {eq: "mr-insta-4.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrInsta5: file(relativePath: {eq: "mr-insta-5.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrInsta6: file(relativePath: {eq: "mr-insta-6.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrLfls1: file(relativePath: {eq: "mr-lfls-1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrLfls2: file(relativePath: {eq: "mr-lfls-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrLfls3: file(relativePath: {eq: "mr-lfls-3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrLfls4: file(relativePath: {eq: "mr-lfls-4.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mrLfls5: file(relativePath: {eq: "mr-lfls-5.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      markSocial: file(relativePath: {eq: "social-mark.jpg"}) {
        childImageSharp {
          fixed(width: 1200, height: 630, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
`)

  const swiper = useRef(null)

  useEffect(()=>{
    setTimeout(function(){
      swiper.current = new Swiper('.swiper-container',{
        slidesPerView: 1,
        spaceBetween: 50,
        freeMode: true,
      })
     },1000)
    
  },[])

  return (
    <>
      <SEO 
        title="Mark Ritchie – Brand & Digital" 
        description="Brand, digital and print for comedian and podcaster, Mark Ritchie." 
        image={data.markSocial.childImageSharp.fixed}
      />
      <Helmet
        htmlAttributes={{
          class: 'mark bg-mrPink text-black',
        }}
      />

      <motion.section
        variants={container}
        initial="hidden" 
        animate="visible"
        className="container"
      >

        <Intro 
          borderColor="border-black border-opacity-20"
          introHeader={["Mark Ritchie —"]}
          introSubHeader={["Brand & digital"]}
          introSummary="Mark Ritchie is a comedian, evangelist, podcaster and international speaker. As a public figure, Mark needed support to establish his online presence with an identity that was fun and creative with a professional edge.">
        </Intro>
        
        <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">
          <div className="border-b"></div>
          <div className="border-b border-l p-6 md:p-10 ">

            <motion.div 
              variants={item}
              transition="easeInOut"
              className="py-20 flex flex-wrap flex-col justify-center align-middle mt-20">
              <motion.div 
                variants={item}
                transition="easeInOut"
                className="mb-20 mx-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" width="88.783" height="97.069" viewBox="0 0 88.783 97.069"><g transform="translate(-138.221 -46.064)"><path d="M189.287,69.389a1.923,1.923,0,0,1,1.894-1.6h24.1a17.1,17.1,0,0,1,2.5.183.585.585,0,0,0,.564-.911,48.671,48.671,0,0,0-80.027-.071.586.586,0,0,0,.48.921h9.608a1.484,1.484,0,0,1,1.467,1.266L153.335,80.4c.864,2.784,2.935,9.619,2.762,10.462-.173.675-.086.844.172.844s.346-.169.518-.844c.173-.844,4.575-7.509,6.56-10.462l7.424-11.221a2.338,2.338,0,0,1,1.985-1.266h11.566a1.3,1.3,0,0,1,1.381,1.6l-9.612,50.693a1.927,1.927,0,0,1-1.9,1.6H162.886a1.3,1.3,0,0,1-1.382-1.6l3.483-16.86c.515-2.855,1.288-6.879,1.72-8.082,0-.012.008-.024.012-.036.165-.576.078-.741-.178-.741-.173,0-.259.084-.518.759a81.272,81.272,0,0,1-5.438,8.184l-3.28,4.556c-.863,1.181-1.381,1.434-2.33,1.434H151.7c-.95,0-1.467-.337-1.813-1.434l-1.554-4.556c-1.554-4.472-2.5-7.425-2.5-8.184,0-.675-.172-.759-.345-.759-.256,0-.259.165-.342.739,0,.013,0,.026,0,.039-.091,1.205-.692,5.227-1.207,8.081l-3.07,16.86a1.927,1.927,0,0,1-1.9,1.6h0a.586.586,0,0,0-.477.925,48.652,48.652,0,0,0,34.257,20.377,3.058,3.058,0,0,0,3.347-2.485Z" transform="translate(0 0)"/><path d="M809.487,749.354c-3.616,0-4.39-2.1-5.509-5.554l-4.046-12.37a2.091,2.091,0,0,0-2.153-1.683h-1.494a.585.585,0,0,0-.575.477l-7,37.189a1.184,1.184,0,0,0,1.518,1.35,48.777,48.777,0,0,0,25.116-18.485.586.586,0,0,0-.478-.924Z" transform="translate(-597.128 -627.624)"/><path d="M1094.477,619.709a.585.585,0,0,0-.145,1.05c1.782,1.13,2.6,2.727,3.586,5.627l2.047,5.807a.585.585,0,0,0,1.1.023,48.518,48.518,0,0,0,3.411-17.916q0-.863-.036-1.718a.586.586,0,0,0-1.088-.276A16.4,16.4,0,0,1,1094.477,619.709Z" transform="translate(-877.468 -519.551)"/><path d="M902.847,455.192l-1.858,10.442a.585.585,0,0,0,.576.688h4.294c4.476,0,6.973-1.6,7.747-5.807s-1.205-5.806-5.6-5.806h-4.588A.585.585,0,0,0,902.847,455.192Z" transform="translate(-700.216 -375.138)"/></g></svg>
              </motion.div>
              <motion.div 
                variants={item}
                transition="easeInOut"
                className="mx-auto">
                  <img className="w-full" alt="Mark Ritchie Logo" src={mrLogo} />
              </motion.div>
            </motion.div>
          </div>
        <div className="border-b border-l"></div>
      </div>

      </motion.section>

      <section className="content">

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl bg-white">
          <div className="border-b"></div>
          <div className="border-b border-l p-6 md:p-10 md:pt-20 ">

          <div className="md:flex md:flex-row md:flex-nowrap md:flex-shrink mb-10 lg:mb-32 xl:px-32">

            <div className="md:flex md:flex-col md:w-1/2 md:p-10 justify-center mob:mb-20">
              <h2>Newspaper</h2>
              <p>Following the development of a simple brand, utilising bold type and a vibrant colour palette, I worked on various content from digital through to print.</p>
              <p className="mt-6">We produced a regular newspaper for his supporters to keep them up-to-date with everything Mark had been involved with over the previous months.</p>
            </div>

            <div className="md:flex md:flex-col md:w-1/2">
              <Img backgroundColor="#CB991B" alt="Mark Ritchie - Newspaper cover designs"  fluid={data.mrNewspaperCovers.childImageSharp.fluid}  />
            </div>

          </div>

          
          <div className="grid grid-cols-12 gap-6 md:gap-10">
            
          
            <div className="col-start-1 col-span-6">
               <Img backgroundColor="#458782" alt="Mark Ritchie - Newspaper pages" fluid={data.mrNewspaper1.childImageSharp.fluid}  />
            </div>

            <div className="col-start-7 col-span-6">
               <Img backgroundColor="#F7D6C6" alt="Mark Ritchie - Newspaper pages" fluid={data.mrNewspaper2.childImageSharp.fluid}  />
            </div>

            <div className="col-start-1 col-span-12">
               <Img backgroundColor="#88D3CC" alt="Mark Ritchie - Newspaper pages" fluid={data.mrNewspaper3.childImageSharp.fluid}  />
            </div>


          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>


      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl bg-hyggeGray">
          <div className="border-b"></div>
          <div className="border-b border-l p-6 md:p-20">

          <div className="md:flex md:flex-row md:flex-nowrap md:flex-shrink mb-10 lg:mb-32 xl:px-32">

            <div className="md:flex md:flex-col md:w-1/2 md:p-10 justify-center">
              <h2>Soul Focus Podcast</h2>
              <p>Artwork and supporting social media content was created for Soul Focus Podcast – Mark's podcast on mental wellbeing.</p>
            </div>

            <div className="md:flex md:flex-col md:w-1/2 p-10 bg-white mob:mt-10">
              <Img backgroundColor="#FF9099" alt="Soul Focus Podcast - Cover design" fluid={data.mrSF.childImageSharp.fluid}  />
            </div>

          </div>


          <div className="grid gap-4 grid-cols-12 md:gap-10 pb-10 lg:pb-20 ">
            <div className="col-start-1 col-span-4">
              <Img backgroundColor="#E8E2D7" alt="Soul Focus Podcast - Instagram content" fluid={data.mrInsta1.childImageSharp.fluid}  />
            </div>
            <div className="col-start-5 col-span-4">
              <Img backgroundColor="#1C1C3A" alt="Soul Focus Podcast - Instagram content" fluid={data.mrInsta2.childImageSharp.fluid}  />
            </div>
            <div className="col-start-9 col-span-4">
              <Img backgroundColor="#F7D6C6" alt="Soul Focus Podcast - Instagram content" fluid={data.mrInsta3.childImageSharp.fluid}  />
            </div>
            <div className="col-start-1 col-span-4">
              <Img backgroundColor="#479791" alt="Soul Focus Podcast - Instagram content" fluid={data.mrInsta4.childImageSharp.fluid}  />
            </div>
            <div className="col-start-5 col-span-4">
              <Img backgroundColor="#E8E2D7" alt="Soul Focus Podcast - Instagram content" fluid={data.mrInsta5.childImageSharp.fluid}  />
            </div>
            <div className="col-start-9 col-span-4">
              <Img backgroundColor="#FF9099" alt="Soul Focus Podcast - Instagram content" fluid={data.mrInsta6.childImageSharp.fluid}  />
            </div>
          </div>


        </div>
        <div className="border-b border-l"></div>
      </div>

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl bg-white">
          <div className="border-b"></div>
          <div className="border-b border-l p-6 md:p-10 ">

          <div className="md:grid gap-4 grid-cols-12 md:gap-10 pb-10 lg:pb-20 ">

            <div className="mob:mb-10 md:col-start-1 md:col-span-7 lg:col-start-2 lg:col-span-5 flex flex-wrap content-center">
              <h2>Like Father Like Son</h2>
              <p>Mark also records a podcast with his son, Jordan. A logo was developed in keeping with Mark's core brand. Supporting illustrations are created for each episode – often representing one of the random topics of conversation they've chatted about.</p>
            </div>

            <div className="mob:p-10 md:col-start-8 md:col-span-5 md:p-8">
              <img backgroundColor="#F1EBE8" alt="Like Father Like Son - Podcast Logo" src={lflsLogo} />
            </div>

            <div className="md:col-start-1 md:col-span-7 mb-6">
              <Img backgroundColor="#1C1C3A" alt="Like Father Like Son - Episode illustration" fluid={data.mrLfls1.childImageSharp.fluid}  />
            </div>

            <div className="md:col-start-8 md:col-span-5 mb-6">
              <Img backgroundColor="#1C1C3A" alt="Like Father Like Son - Episode illustration" fluid={data.mrLfls2.childImageSharp.fluid}  />
            </div>
  

            <div className="col-start-2 col-span-10">

              <div className="md:grid gap-4 grid-cols-12 md:gap-10 pb-10 lg:pb-20 ">

                <div className="col-start-1 col-span-4 mb-6">
                  <Img backgroundColor="#FF5154" alt="Like Father Like Son - Episode illustration" fluid={data.mrLfls3.childImageSharp.fluid}  />
                </div>

                <div className="col-start-5 col-span-4 mb-6">
                  <Img backgroundColor="#1C1C3A" alt="Like Father Like Son - Episode illustration" fluid={data.mrLfls4.childImageSharp.fluid}  />
                </div>

                <div className="col-start-9 col-span-4 mb-6">
                  <Img backgroundColor="#F7D6C6" alt="Like Father Like Son - Episode illustration" fluid={data.mrLfls5.childImageSharp.fluid}  />
                </div>
              </div>

            </div>

          </div>

        </div>
        <div className="border-b border-l"></div>
      </div>

      </section>

    </>
  )
}



export default MarkRitchie

